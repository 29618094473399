// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SplitPayments from "../../blocks/SplitPayments/src/SplitPayments";
import ContentFlag from "../../blocks/ContentFlag/src/ContentFlag";
import BlogPostsManagement from "../../blocks/BlogPostsManagement/src/BlogPostsManagement";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import CfRaisePaymentRequest from "../../blocks/CfRaisePaymentRequest/src/CfRaisePaymentRequest";
import ReviewPrompt from "../../blocks/ReviewPrompt/src/ReviewPrompt";
import CentralisedBilling from "../../blocks/CentralisedBilling/src/CentralisedBilling";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import RSSFeeds from "../../blocks/rssfeeds/src/RSSFeeds";
import Payments from "../../blocks/Payments/src/Payments";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import RequestManagement from "../../blocks/RequestManagement/src/RequestManagement";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Customform from "../../blocks/customform/src/Customform";
import TicketSystem from "../../blocks/TicketSystem/src/TicketSystem";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Notifications from "../../blocks/notifications/src/Notifications";
import CollectTransactionFees from "../../blocks/CollectTransactionFees/src/CollectTransactionFees";
import Analytics from "../../blocks/analytics/src/Analytics";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Trending from "../../blocks/Trending/src/Trending";
import Interactivefaqs from "../../blocks/interactivefaqs/src/Interactivefaqs";
import AddInteractivefaqs from "../../blocks/interactivefaqs/src/AddInteractivefaqs";
import MultilevelApproval from "../../blocks/MultilevelApproval/src/MultilevelApproval";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import DigitalSignatures from "../../blocks/DigitalSignatures/src/DigitalSignatures";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import Share from "../../blocks/share/src/Share";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import QuoteManagement from "../../blocks/QuoteManagement/src/QuoteManagement";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Chat9 from "../../blocks/Chat9/src/Chat9";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import VideoEmbeddingYoutube from "../../blocks/VideoEmbeddingYoutube/src/VideoEmbeddingYoutube";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import CfReviewChatAdmin from "../../blocks/CfReviewChatAdmin/src/CfReviewChatAdmin";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Search from "../../blocks/search/src/Search";
import TaskList from "../../blocks/TaskList/src/TaskList";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";



const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
SplitPayments:{
 component:SplitPayments,
path:"/SplitPayments"},
ContentFlag:{
 component:ContentFlag,
path:"/ContentFlag"},
BlogPostsManagement:{
 component:BlogPostsManagement,
path:"/BlogPostsManagement"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
CfRaisePaymentRequest:{
 component:CfRaisePaymentRequest,
path:"/CfRaisePaymentRequest"},
ReviewPrompt:{
 component:ReviewPrompt,
path:"/ReviewPrompt"},
CentralisedBilling:{
 component:CentralisedBilling,
path:"/CentralisedBilling"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
AdminConsole2:{
 component:AdminConsole2,
path:"/AdminConsole2"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
RSSFeeds:{
 component:RSSFeeds,
path:"/RSSFeeds"},
Payments:{
 component:Payments,
path:"/Payments"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Customform:{
 component:Customform,
path:"/Customform"},
TicketSystem:{
 component:TicketSystem,
path:"/TicketSystem"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
CollectTransactionFees:{
 component:CollectTransactionFees,
path:"/CollectTransactionFees"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Trending:{
 component:Trending,
path:"/Trending"},
Interactivefaqs:{
 component:Interactivefaqs,
path:"/Interactivefaqs"},
AddInteractivefaqs:{
 component:AddInteractivefaqs,
path:"/AddInteractivefaqs"},
MultilevelApproval:{
 component:MultilevelApproval,
path:"/MultilevelApproval"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
DigitalSignatures:{
 component:DigitalSignatures,
path:"/DigitalSignatures"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
ReviewAndApproval:{
 component:ReviewAndApproval,
path:"/ReviewAndApproval"},
Share:{
 component:Share,
path:"/Share"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
TermsAndConditions:{
 component:TermsAndConditions,
path:"/TermsAndConditions"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
QuoteManagement:{
 component:QuoteManagement,
path:"/QuoteManagement"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Chat9:{
 component:Chat9,
path:"/Chat9"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
VideoEmbeddingYoutube:{
 component:VideoEmbeddingYoutube,
path:"/VideoEmbeddingYoutube"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
CfReviewChatAdmin:{
 component:CfReviewChatAdmin,
path:"/CfReviewChatAdmin"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Search:{
 component:Search,
path:"/Search"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},

  Home: {
component:Customisableusersubscriptions,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
